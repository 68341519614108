import { Button, Divider, Select, Skeleton, Tooltip } from 'antd'
import { LatLngExpression } from 'leaflet'
import React from 'react'
import {MapContainer, ScaleControl, TileLayer} from 'react-leaflet'
import { Facility } from '../../domain/entities/IFacility'
import France from '../assets/img/icon/france.png'
import useMediaQuery from '../hooks/useMediaQuery'
import { useInteractiveMapLogic } from '../logic/InteractiveMapLogic'
import Legend from './Legend'
import MapMarker from './MapMarker'
import MinimapControl, { Position } from './MinimapControl'
import SelectCloseIcon from './SelectCloseIcon'
import SelectSuffixIcon from './SelectSuffixIcon'

const InteractiveMap: React.FC = () => {
  let popupRef: any = React.createRef()
  let mapRef: any = React.createRef()

  const { state, init, onSearchPlace, handlePlaceChange, handleTypeChange, gotoFrance, normalizeTextInput } =
    useInteractiveMapLogic(mapRef, popupRef)

  const isSmallDevice = useMediaQuery(470)
  const franceCoordinates: LatLngExpression = [46.8732324, 2.0106608]
  const leafletTilesURL = 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'

  const { loading, searchLoader, searchResultat, zoom, facilityTypes, scrollWheelZoom, remoteMarkers } = state

  React.useEffect(() => {
    init()
  }, [loading])

  return (
    <div className="flex flex-col w-full gap-3">
      <div className="flex flex-col xl:flex-row gap-8 lg-gap-0 w-full xs:items-center xs:justify-between">
        <div className="flex flex-col basis-1/3 gap-2">
          <span>Rechercher une ville, un département, un code postal...</span>
          <Select
            showSearch
            allowClear
            notFoundContent=""
            loading={searchLoader}
            options={searchResultat}
            onSearch={onSearchPlace}
            optionFilterProp="children"
            className={`max-w-[440px]`}
            onChange={handlePlaceChange}
            clearIcon={<SelectCloseIcon />}
            filterOption={normalizeTextInput}
            suffixIcon={<SelectSuffixIcon />}
            placeholder="Renseignez votre recherche"
            size={isSmallDevice ? 'middle' : 'large'}
          />
        </div>
        <div className="flex basis-1/3 xs:items-center gap-2 flex-col sm:flex-row">
          <div className="flex flex-col gap-2">
            <span>Filtrer par types</span>
            <Select
              showSearch
              allowClear
              notFoundContent=""
              mode="multiple"
              options={facilityTypes}
              optionFilterProp="children"
              className={`max-w-[440px]`}
              clearIcon={<SelectCloseIcon />}
              suffixIcon={<SelectSuffixIcon />}
              size={isSmallDevice ? 'middle' : 'large'}
              placeholder="Sélectionnez un type d'établissement"
              onChange={(value: string[]) => handleTypeChange(value)}
              filterOption={(input, option: any) =>
                option && (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </div>
          <Tooltip title="Recentrer sur la France">
            <Button
              size="large"
              className="flex items-center top-4 w-[55px] mx-auto xs:mx-0"
              onClick={() => gotoFrance(franceCoordinates)}
            >
              <img src={France} className="w-5 h-5" alt="france-icon" />
            </Button>
          </Tooltip>
        </div>
      </div>

      <Divider />

      {loading ? (
        <Skeleton />
      ) : (
        <>
          <MapContainer
            zoom={zoom}
            ref={mapRef}
            zoomSnap={0.25}
            center={franceCoordinates}
            scrollWheelZoom={scrollWheelZoom}
          >
            <TileLayer url={leafletTilesURL} />
            {remoteMarkers.map((marker: Facility, i: number) => (
              <MapMarker marker={marker} ref={popupRef} key={i} />
            ))}
            <MinimapControl position={Position.BOTTOM_RIGHT} zoom={zoom} />
            <ScaleControl metric={true} imperial={false} />

          </MapContainer>

          <Divider />

          {facilityTypes && <Legend facilityTypes={facilityTypes} />}
        </>
      )}
    </div>
  )
}

export default InteractiveMap
