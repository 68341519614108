import { Card, Divider } from 'antd'
import { Facility, FacilityCode } from '../../domain/entities/IFacility'
import { codeToColour, korianBlue } from './Icons/customIcons'
import ExternalLinkIcon from './Icons/ExternalLinkIcon'
import MarkerDetailPhoneAction from './MarkerDetailPhoneAction'

interface Props {
  marker: Facility
  icon: React.ReactNode
}

const MarkerDetails: React.FC<Props> = ({ marker, icon }) => {
  return (
    <Card
      className="card-marker"
      bordered={false}
      actions={[
        <MarkerDetailPhoneAction
          phoneNumber={marker.phone}
          buttonColor={codeToColour.get(marker.type.code) ?? korianBlue}
          labelColor={[FacilityCode.F006T].includes(marker.type.code) ? korianBlue : 'white'}
        />
      ]}
    >
      {marker.url ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={marker.url}
          className="flex gap-1 text-md items-center text-cyan-500 font-bold underline pt-4"
        >
          {icon} {marker.name} <ExternalLinkIcon scale={0.3} color={korianBlue} />
        </a>
      ) : (
        <p className="flex gap-1 text-md text-cyan-500 font-bold">
          {icon} {marker.name}
        </p>
      )}

      <Divider />

      <div className="flex flex-col pb-4 text-sm">
        <span className="flex gap-2">
          <b className="min-w-[86px]">Type:</b> {marker.type?.name} ({marker.type?.code})
        </span>
        <span className="flex gap-2">
          <b className="min-w-[86px]">Adresse:</b> {marker.address ?? <i>Non renseigné</i>}
        </span>
        <span className="flex gap-2">
          <b className="min-w-[86px]">Ville:</b> {marker.city ?? <i>Non renseignée</i>}
        </span>
        <span className="flex gap-2">
          <b className="min-w-[86px]">Code postal:</b> {marker.zipcode ?? <i>Non renseigné</i>}
        </span>
      </div>
    </Card>
  )
}

export default MarkerDetails
