import {DomUtil, LatLngExpression} from 'leaflet'
import { useState } from 'react'
import { Facility, FacilityType } from '../../domain/entities/IFacility'
import { useDependencies } from '../hooks/useDependencies'

export interface SearchResult {
  label: string
  latitude: number
  longitude: number
  mapZoom: number
  name: string
  type: string
  value: string
  zipcode: string
}

export interface IInteractiveMapViewModelState {
  allData: Facility[]
  remoteMarkers: Facility[]
  lastSelectedMarker: any // contiendra l'objet du dernier marker de leafleat qui a ouvert sa popup
  loading: boolean
  searchLoader: boolean
  searchResultat: any[]
  position: LatLngExpression
  zoom: number
  center: any
  selectedMarker: any
  filterByName: boolean
  scrollWheelZoom: boolean
  selectedCity: any
  selectedType: any
  facilityTypes: FacilityType[]
  dataToShow: []
  focusMarker: any
  showList: boolean
  searchText: string
  searchCode: string
  options: {
    enableHighAccuracy: boolean
    timeout: number
    maximumAge: number
  }
}

export const useInteractiveMapLogic = (mapRef: any, popupRef: any) => {
  const [state, setState] = useState<IInteractiveMapViewModelState>({
    allData: [],
    searchResultat: [],
    loading: true,
    searchLoader: false,
    remoteMarkers: [],
    lastSelectedMarker: null, // contiendra l'objet du dernier marker de leafleat qui a ouvert sa popup
    position: [46.8732324, 2.0106608],
    zoom: 5.5,
    center: null,
    selectedMarker: null,
    filterByName: false,
    scrollWheelZoom: true,
    selectedCity: null,
    selectedType: null,
    facilityTypes: [],
    dataToShow: [],
    focusMarker: null,
    showList: false,
    searchText: '',
    searchCode: '',
    options: {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }
  })

  const { facilityProvider } = useDependencies()

  function init() {
    getData()
  }

  function flyTo(position?: LatLngExpression, zoom?: number) {
    const leaflet: HTMLDivElement | null = document.querySelector('.leaflet-container')
    if (leaflet?.offsetParent !== null) {
      // on retire le focus du marker
      if (state.selectedCity) {
        popupRef.current.leafletElement.options.leaflet.map.closePopup()
        setState((prev: any) => {
          return {
            ...prev,
            remoteMarkers: state.remoteMarkers.map((marker: any) => {
              if (marker.focus) {
                marker.focus = false
                return marker
              }
              return null
            })
          }
        })
      }

      state.lastSelectedMarker && state.lastSelectedMarker.closePopup()
      mapRef.current && mapRef.current.flyTo(position ?? state.position, zoom ?? state.zoom)
    }
  }

  function onSearchPlace(value: string): void {
    if (value.length >= 2) {
      setState(state => ({ ...state, searchLoader: true }))
      setState(state => ({ ...state, searchResultat: [] }))

      facilityProvider
        .getPlaces({ searchValue: value })
        .then(places => {
          setState(state => ({ ...state, searchResultat: places }))
        })
        .catch(e => console.error(e))
        .finally(() => setState(state => ({ ...state, searchLoader: false })))
    } else {
      setState(state => ({ ...state, searchResultat: [] }))
    }
  }

  function gotoFrance(franceCoordinates: LatLngExpression): void {
    flyTo(franceCoordinates)
  }

  async function getFacilityTypes() {
    try {
      const facilityTypes = await facilityProvider.getFacilityTypes()
      setState(state => ({
        ...state,
        facilityTypes
      }))
    } catch (e) {
      console.error(e)
    } finally {
      setState(state => ({ ...state, loading: false }))
    }
  }

  async function getData() {
    try {
      const rawData = await facilityProvider.getFacilities({})
      const dataToDisplay = rawData.filter((e: any) => {
        if (e.latitude !== null && e.longitude !== null) {
          e.isSelected = false
          e.focus = false
          return e
        }
        return null
      })

      setState(state => ({
        ...state,
        allData: dataToDisplay,
        remoteMarkers: dataToDisplay
      }))
      getFacilityTypes()
    } catch (e) {
      console.error(e)
    }
  }

  function handlePlaceChange(value: string): void {
    const selectedPlace: SearchResult | undefined = state.searchResultat.find((e: any) => e.id === value)
    if (selectedPlace) {
      const position: LatLngExpression = [selectedPlace.latitude, selectedPlace.longitude]
      flyTo(position, selectedPlace.mapZoom)
    }
  }

  function handleTypeChange(value: string[]): void {
      setState(state => ({
        ...state,
        selectedType:  value,
        remoteMarkers: value.length === 0 ? state.allData : state.allData.filter((el: { type: { code: string } }) => value.includes(el.type.code))
      }))
  }


  function normalizeTextInput(input: string, option: any) {
    return (option?.label ?? '').toLowerCase().includes(
      input
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    )
  }

  return {
    state,
    init,
    gotoFrance,
    onSearchPlace,
    handleTypeChange,
    handlePlaceChange,
    normalizeTextInput
  }
}
