import 'antd/dist/antd.min.css'
import 'leaflet/dist/leaflet.css'
import Layout from './components/Layout'
import { DependenciesContext } from './contexts/DependenciesContext'
import { createDependencies } from './dependencies'

function App() {
  return (
    <DependenciesContext.Provider value={createDependencies()}>
      <Layout />
    </DependenciesContext.Provider>
  )
}

export default App
