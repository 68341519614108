import React from 'react'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import MinimapBounds from './MinimapBounds'

export enum Position {
  BOTTOM_LEFT = 'leaflet-bottom leaflet-left',
  BOTTOM_RIGHT = 'leaflet-bottom leaflet-right',
  TOP_LEFT = 'leaflet-top leaflet-left',
  TOP_RIGHT = 'leaflet-top leaflet-right'
}

interface IMinimapControlProps {
  position: Position
  zoom: number
}

const MinimapControl: React.FC<IMinimapControlProps> = ({ position, zoom }) => {
  const parentMap = useMap()
  const mapZoom = zoom / 2 || 3

  // Memoize the minimap so it's not affected by position changes
  const minimap = React.useMemo(
    () => (
      <MapContainer
        style={{ height: 80, width: 80 }}
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),

    //eslint-disable-next-line
    []
  )

  const positionClass = position || Position.TOP_RIGHT
  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>
  )
}

export default MinimapControl
