import { Map } from 'leaflet'
import React from 'react'
import { Rectangle, useMap, useMapEvent } from 'react-leaflet'
import { useEventHandlers, useLeafletContext } from '@react-leaflet/core'

interface IMinimapBoundsProps {
  parentMap: Map
  zoom: number
}

const MinimapBounds: React.FC<IMinimapBoundsProps> = ({ parentMap, zoom }) => {
  const minimap = useMap()
  const context = useLeafletContext()

  // Clicking a point on the minimap sets the parent's map center
  const onClick = React.useCallback(
    (e: any) => {
      parentMap.setView(e.latlng, parentMap.getZoom())
    },
    [parentMap]
  )
  useMapEvent('click', onClick)

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = React.useState(parentMap.getBounds())
  const onChange = React.useCallback(() => {
    setBounds(parentMap.getBounds())
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom)
  }, [minimap, parentMap, zoom])

  // Listen to events on the parent map
  const handlers = React.useMemo(
    () => ({ move: onChange, zoom: onChange }),
    //eslint-disable-next-line
    []
  )
  useEventHandlers({ instance: parentMap, context }, handlers)

  return <Rectangle bounds={bounds} pathOptions={{ weight: 1 }} />
}

export default MinimapBounds
