import React from 'react'
import { Helmet } from 'react-helmet'
import '../css/main.scss'
import 'leaflet/dist/leaflet.css'
import Header from './Header'
import InteractiveMap from './InteractiveMap'
interface LayoutProps {}

const Layout: React.FC<LayoutProps> = () => {
  return (
    <>
      <Helmet>
        <title>Store Locator - Localisation etablissements</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Liste des établissements du groupe Korian." />
      </Helmet>
      <Header />
      <section className="flex w-100 items-center justify-center py-10">
        <div className="flex w-10/12 items-center justify-center" id="header">
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-4xl header-text">Trouvez un établissement </h2>
            <h2 className="text-4xl header-text">
              {' '}
              à proximité de <span className="text-korian-blue">chez vous</span>{' '}
            </h2>
          </div>
        </div>
      </section>
      <section className="flex w-100 items-center justify-center py-10">
        <div className="flex w-10/12 items-center justify-center">
          <InteractiveMap />
        </div>
      </section>
    </>
  )
}

export default Layout
