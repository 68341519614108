import { useState, useCallback, useEffect } from 'react'

const useMediaQuery = (width: number) => {
  const [targetReached, setTargetReached] = useState(false)

  const updateTarget = useCallback((event: MediaQueryListEvent) => {
    if (event.matches) {
      setTargetReached(true)
    } else {
      setTargetReached(false)
    }
  }, [])

  useEffect(() => {
    // will return true if the current window is <= "width" pixels wide
    const media = window.matchMedia(`(max-width: ${width}px)`)

    media.addEventListener('change', event => updateTarget(event))

    if (media.matches) {
      setTargetReached(true)
    }

    return () => media.removeEventListener('change', e => updateTarget(e))
  }, [])

  return targetReached
}

export default useMediaQuery
