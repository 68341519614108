import React from 'react'
import { Marker as LeafletMarker, Popup } from 'react-leaflet'
import { Facility } from '../../domain/entities/IFacility'
import { createIcon, createSVG } from './Icons/customIcons'
import MarkerDetails from './MarkerDetails'

interface Props {
  marker: Facility
}

const MapMarker = React.forwardRef<any, Props>(({ marker }, ref) => {
  return (
    <LeafletMarker
      key={marker?.id}
      icon={createIcon(marker.type?.code)}
      position={[+marker?.latitude, +marker?.longitude]}
    >
      <Popup ref={ref}>
        <MarkerDetails marker={marker} icon={createSVG(marker.type?.code)} />
      </Popup>
    </LeafletMarker>
  )
})

export default MapMarker
