import React from 'react'
import { FacilityCode, FacilityType } from '../../domain/entities/IFacility'
import { createSVG } from './Icons/customIcons'

interface ILegendProps {
  facilityTypes: FacilityType[]
}

const Legend: React.FC<ILegendProps> = ({ facilityTypes }) => {
  // https://gitlab.korian.cloud/developpement/marketing/store-locator/-/issues/40
  // Affichage de la légende dans un ordre bien précis
  const order = [
    FacilityCode.F001T, // Maison de retraite médicalisée (EHPAD)
    FacilityCode.F002T, // Résidence Seniors
    FacilityCode.F006T, // Clinique SMR
    FacilityCode.F004T, // Clinique de santé mentale
    FacilityCode.F007T, // Hospitalisation à domicile
    FacilityCode.F008T, // Services de Soins Infirmiers à Domicile (SSIAD)
    FacilityCode.F005T, // Hôpital de jour 
    FacilityCode.F010T, // Service à domicile Petit-fils
    FacilityCode.F009T, // Colocations Âges et vies
    FacilityCode.F003T  // Maison d'accueil spécialisée
  ]
  const orderedTypes = order.map(
      code => facilityTypes.find(e => e.code === code)
  ).filter(el => el);

  return (
    <>
      <p className="underline">Légende:</p>
      <div className="flex flex-wrap gap-3 w-full">
        {orderedTypes.map((type: any, key: number) => (
          <div key={`facility-type-${key}`} className="flex items-center gap-2 text-sm text-gray-700 min-w-[300px] max-w-[300px]">
            <span className={`min-w-[20px]`}>{createSVG(type.value)}</span>
            <p className={`m-0`}>{type.label}</p>
          </div>
        ))}
      </div>
    </>
  )
}

export default Legend
