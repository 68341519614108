import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, ResponseType } from 'axios'
import { HttpHeaders, IHttpClient } from '../../domain/models/IHttpClient'

export class AxiosHttpClient implements IHttpClient {
  public baseUrl: string
  private httpInstance: AxiosInstance

  constructor(baseURL: string) {
    const headers = {
      'content-type': 'application/json'
    } as unknown as AxiosRequestHeaders

    const customConfig: AxiosRequestConfig = {
      baseURL,
      headers
    }
    this.baseUrl = baseURL
    this.httpInstance = axios.create(customConfig)
  }

  async post<R>(url: string, body: object, headers?: HttpHeaders, responseType?: ResponseType): Promise<R> {
    const response = await this.httpInstance.post(url, body, { headers: headers, responseType })
    return response.data as R
  }

  async get<R>(url: string, headers?: HttpHeaders, responseType?: ResponseType): Promise<R> {
    const response = await this.httpInstance.get(url, { headers: headers, responseType })
    return response.data as R
  }
}
