import React from 'react'
import Logo from '../assets/img/v2/logo-clariane.png'

const Header: React.FC = () => {
  return (
    <header className="sticky top-0 z-[2000] bg-white">
      <section className="flex w-100 py-3 items-center justify-center shadow-md section-header">
        <div className="flex flex-col sm:flex-row w-10/12 items-center justify-around">
          <div className="flex justify-evenly w-full sm:w-auto">
            <div className="flex items-center gap-3">
              <a href="https://www.korian.fr/" target="_blank" rel="noreferrer">
                <img className="w-44" src={Logo} alt="korian-logo-white" />
              </a>
            </div>
          </div>

          <p className={`m-0 text-center text-gray-500 text-caution-app`}>
            Cette page web est un outil réservé à un usage interne des collaborateurs du groupe Clariane&nbsp;et&nbsp;Webhelp.
          </p>
        </div>
      </section>
    </header>
  )
}

export default Header
