import React from 'react'

interface Props {
  color?: string
  scale?: number
}

const PinIcon: React.FC<Props> = ({ color, scale }) => {
  return (
    <svg
      width={21.633}
      height={24.42}
      viewBox="0 0 21.633 24.42"
      transform={`scale(${scale ?? 1})`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(1 1)">
        <path
          stroke="white"
          strokeWidth={2}
          fill={color ?? 'white'}
          transform="translate(-3 -2)"
          d="M12.816,2A9.9,9.9,0,0,0,10.3,21.459L12.816,24l2.52-2.541A9.9,9.9,0,0,0,12.816,2Z"
        />
      </g>
    </svg>
  )
}

export default PinIcon
