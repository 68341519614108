import { AxiosHttpClient } from '../../data/models/AxiosHttpClient'
import { FacilityProvider } from '../../data/providers/FacilityProvider'
import { IHttpClient } from '../../domain/models/IHttpClient'
import { IFacilityProvider } from '../../domain/providers/IFacilityProvider'

export interface IDependencies {
  httpClient: IHttpClient
  facilityProvider: IFacilityProvider
}

export const createDependencies = (): IDependencies => {
  const httpClient = new AxiosHttpClient(ApiConfig.apiUrl)
  const facilityProvider = new FacilityProvider(httpClient)

  return {
    httpClient,
    facilityProvider
  }
}
