import React from 'react'
import PhoneIcon from './Icons/PhoneIcon'

function formatPhoneNumber(phone: string) {
  phone = phone.replace(/\s/g, '')
  const format = phone.substring(0, 4) === '0800' ? 'xxxx xxx xxx' : 'xx xx xx xx xx'

  if (phone.length !== format.replace(/\s/g, '').length) {
    throw new Error(`Le numéro de téléphone ne peut être exprimé dans le format "${format}"`)
  }

  let i = 0
  let retVal = ''
  for (const char of format) {
    switch (char) {
      case 'x':
        retVal += phone.charAt(i)
        i++
        break

      case ' ':
        retVal += ' '
        break

      default:
        throw new Error(`Le caractère "${char}" est invalide pour exprimer un format de téléphone`)
    }
  }
  return retVal
}

interface Props {
  phoneNumber?: string
  buttonColor: string
  labelColor: string
}

const MarkerDetailPhoneAction: React.FC<Props> = ({ phoneNumber, buttonColor, labelColor }) => {
  const defaultPhoneNumber = '01 85 65 76 26'
  return (
    <div
      style={{ backgroundColor: buttonColor }}
      onClick={() => window.open(`tel:${phoneNumber ?? defaultPhoneNumber}`, '_self')}
      className="flex items-center justify-center gap-2 mt-2 mx-auto px-2 py-2 hover:scale-[1.02] drop-shadow-md rounded-md transition duration-200"
    >
      <PhoneIcon color={labelColor} />
      <span className={`text-md font-bold`} style={{ color: labelColor }}>
        {phoneNumber ? `${formatPhoneNumber(phoneNumber)}` : defaultPhoneNumber}
      </span>
    </div>
  )
}

export default MarkerDetailPhoneAction
