import React from 'react'
import { CloseCircleFilled } from '@ant-design/icons'

// composant nécessaire pour avoir le bouton clear à côté du bouton de recherche dans le <Select> de Antd
const SelectCloseIcon: React.FC = () => {
  return (
    <div style={{ position: 'relative' }}>
      <CloseCircleFilled style={{ position: 'absolute', right: '20px' }} />
    </div>
  )
}

export default SelectCloseIcon
