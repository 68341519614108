export enum FacilityCode {
  F001T = 'F001T',
  F002T = 'F002T',
  F003T = 'F003T',
  F004T = 'F004T',
  F005T = 'F005T',
  F006T = 'F006T',
  F007T = 'F007T',
  F008T = 'F008T',
  F009T = 'F009T',
  F010T = 'F010T'
}

export interface FacilityType {
  '@id': string
  '@type': string
  code: FacilityCode
  name: string
}

interface FacilityDepartment {
  '@id': string
  '@type': string
  code: string
  name: string
}

export interface Facility {
  '@id': string
  '@type': string
  id: number
  code: string
  name: string
  city: string
  address: string
  zipcode: string
  longitude: string
  latitude: string
  roomPriceS: string
  roomPriceD: string
  phone: string
  photo: string
  url: string
  type: FacilityType
  department: FacilityDepartment
  plus1: boolean
  plus2: boolean
  plus3: boolean
  mapZoom: number
  isDeleted: boolean
}

export interface Place {
  id: string
  name: string
  label: string
  latitude: string
  longitude: string
  code: string
  zipcode: string
  type: FacilityType
  mapZoom: number
  value: string
}
