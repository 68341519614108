import { Facility, FacilityType, Place } from '../../domain/entities/IFacility'
import { IHttpClient } from '../../domain/models/IHttpClient'
import { IFacilityProvider, IGetFacilityParams, IGetPlacesParams } from '../../domain/providers/IFacilityProvider'
import { ApiRoutes } from '../constants/apiRoutes'
import RequestErrorHandler from '../models/RequestErrorHandler'

export class FacilityProvider implements IFacilityProvider {
  constructor(private httpClient: IHttpClient) {}

  async getFacilityTypes(): Promise<FacilityType[]> {
    let retval: any[] = []
    try {
      const response: any = await this.httpClient.get(ApiRoutes.facilityTypes)

      retval = response['hydra:member'].map((type: any) => ({
        code: type.code,
        value: type.code,
        label: type.name
      }))
    } catch (error: unknown) {
      RequestErrorHandler.handleRequestError(error)
    }

    return retval as FacilityType[]
  }

  async getFacilities(params: IGetFacilityParams): Promise<Facility[]> {
    let retval: any[] = []
    try {
      const { searchValue } = params
      const response: any = await this.httpClient.get(ApiRoutes.facilities(searchValue))
      retval = response['hydra:member']
    } catch (error: unknown) {
      RequestErrorHandler.handleRequestError(error)
    }

    return retval as Facility[]
  }

  async getPlaces(params: IGetPlacesParams): Promise<Place[]> {
    let retval: Place[] = []
    try {
      const { searchValue } = params
      const response: any = await this.httpClient.get(ApiRoutes.places(searchValue))

      const isNumber = (n: string | undefined) => {
        return n && !isNaN(parseFloat(n)) && !isNaN(parseFloat(n) - 0)
      }

      const capitalize = (s: string) => {
        return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()
      }

      response['hydra:member']
        .forEach((place: any) => {
          retval.push({
            id: place.id,
            value: place.id,
            type: place.type,
            code: place.code,
            zipcode: place.zipcode,
            latitude: place.latitude,
            longitude: place.longitude,
            name: capitalize(place.name),
            label: capitalize(place.type === 'Ville' ? place.search_name : `${place.name} (${place.code})`),
            mapZoom: parseInt(place.map_zoom, 10)
          })
        })
    } catch (error: unknown) {
      RequestErrorHandler.handleRequestError(error)
    }

    return retval
  }
}
