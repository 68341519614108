import React from 'react'
import { korianBlue } from './customIcons'

interface Props {
  color?: string
  scale?: number
}

const PhoneIcon: React.FC<Props> = ({ color, scale }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 119 119"
      transform={`scale(${scale ?? 1})`}
      xmlns="http://www.w3.org/2000/svg"
      fill={color ?? korianBlue}
    >
      <path d="M72.9998 15V6.5C103.8 7.7 112.5 33.3333 113 46H104C101.6 21.2 82.3331 15 72.9998 15Z" />
      <path d="M72.9998 33C72.9997 30 73.0003 28 72.9998 24C82.5 24 95 34 94.5 46H85.9998C85 37.5 81.5 34.5 72.9998 33Z" />
      <path d="M47.9991 24.9995C47.9991 21.5001 42 3.53792 37.4991 2.49946C31 0.999984 18.9999 0.999728 7.99905 2.49946C3.88577 3.06021 0.703222 6.35868 0.999051 10.4995C6.00006 80.4995 57.5001 118.499 110.499 118.499C114.1 118.499 116.951 116.058 117.499 112.499C118.5 106 117.001 84.5006 116 81.5C114.535 77.1072 102.501 73.0006 94.5 72.5C86.9096 72.0251 84.5 80.4994 78.4991 84.4994C62 80.4994 41.5 75 33.9991 39.9995C38.5 35 47.9991 31.5001 47.9991 24.9995Z" />
    </svg>
  )
}

export default PhoneIcon
