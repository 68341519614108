export class DomainError extends Error {
  name: string // error type
  intlKey?: string // localization key to display error message to user
  cause?: any // underlying cause of the error

  constructor(details: string, cause?: any) {
    super(details)
    this.name = this.constructor.name
    this.cause = cause
    this.intlKey = 'Error.Unknown'
  }
}

export namespace NavigationError {
  export class PageNotFOund extends DomainError {
    constructor() {
      super('')
      this.intlKey = 'Error.PageNotFound'
    }
  }
}

export namespace RequestError {
  export class RequestFailedError extends DomainError {
    constructor(details: string, cause?: any) {
      super(details, cause)
      this.intlKey = 'Error.RequestFailed'
    }
  }
  export class UnauthorizedError extends DomainError {
    constructor(details: string, cause?: any) {
      super(details, cause)
      this.intlKey = 'Error.Unauthorized'
    }
  }

  export class InvalidRequestFormatError extends DomainError {
    constructor(details: string, cause?: any) {
      super(details, cause)
      this.intlKey = 'Error.InvalidRequestFormat'
    }
  }
  export class InvalidResponseFormatError extends DomainError {
    constructor(details: string, cause?: any) {
      super(details, cause)
      this.intlKey = 'Error.InvalidResponseFormat'
    }
  }

  export class NoServerResponseError extends DomainError {
    constructor(details: string, cause?: any) {
      super(details, cause)
      this.intlKey = 'Error.NoServerResponseError'
    }
  }

  export class ServerError extends DomainError {
    constructor(details: string, cause?: any) {
      super(details, cause)
      this.intlKey = 'Error.ServerError'
    }
  }

  export class NotFoundError extends DomainError {
    constructor(details: string, cause?: any) {
      super(details, cause)
      this.intlKey = 'Error.NotFoundError'
    }
  }
}
